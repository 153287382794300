/* You can add global styles to this file, and also import other style files */

/* html, body { height: 100vh;} */

body { margin: 0; font-family: Verdana, Geneva, Tahoma, sans-serif; }
input[type=file]::file-selector-button {
    padding: 5px 10px;
    border-radius: .3em;
    background-color: #3f51b5;
    color: #f5f5f5f5;
    transition: 1s;
    outline: none;
    border:none;
    cursor: pointer;
  }

::ng-deep .mat-form-field-required-marker { color: red !important }


.loading-bar {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.spinner{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50% ,-50%);
  z-index: 100;
}

.active-title {
  font-size: 15px;
  background: #c2b6d9;
  padding: 10px 10px;
  border-radius: 5px;
  font-weight: 500;
  color: #580825;
}

mat-error{
  font-size: 15px;
}

.mat-header-cell{
  font-size: 15px;
  color: #10249f;
}

.deletebtn{
  cursor: pointer;
  margin-left: 15px;
}